.media-header {
    background-color: #bb4646;
}

.jam-form {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: auto;
}
.jam-form-head {
    font-size: 15px;
    padding-top: 15px;
    color: #4CAF50;
    font-family: 'Courier New', Courier, monospace;
}
.jam-form-control {
    width: 50%;
    /* height: auto; */
    /* position: absolute; */
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    border-radius: 15px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #DDD;
}

.jam-form-control-input {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px;
    padding: 0px;
    height: 100%;


}

.jam-form-input {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5px;

}

.jam-input {
    width: 100%;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s;
}

.jam-form-button {
    display: flex;
    /* padding: 0px 55px 0px 55px; */
}

.jam-button {
    /* padding: 5px; */
    border-radius: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
    /* margin: 5px; */
    border: none;
    cursor: pointer;
    /* background-color: #4CAF50; */
    width: 100%;
    font-size: 16px;
    /* padding: 7px; */
    margin: 10px;
    border-width: 2px;
    border-color: #354251;
    border-style: solid;
}
.jam-button:hover {
    /* padding: 5px; */
    border-radius: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
    /* margin: 5px; */
    border: none;
    cursor: pointer;
    /* background-color: #4CAF50; */
    width: 100%;
    font-size: 16px;
    /* padding: 7px; */
    margin: 10px;
    border-width: 2px;
    border-color: #db8c03;
    border-style: solid;
}
.jam-span-button {
    padding: 10px;
}

.jam-form-control-button {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0px;
    padding: 0px;
}

.jam-error {
    color:#bb4646;
}

.jam-div-button {
    cursor: pointer;
    font-size: 16px;
    padding: 2px;
}
.jam-tb-control {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.jam-button-tb {
    display: flex;
}

.jam-button-tb {
    margin: 20px;
    display: flex;
    /* padding: 5px; */
    border-radius: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    /* margin: 5px; */
    border: none;
    cursor: pointer;
    /* background-color: #4CAF50; */
    font-size: 16px;
    /* padding: 7px; */
    border-width: 2px;
    border-color: #354251;
    border-style: solid;
}
.jam-button-tb:hover {
    margin: 20px;
    display: flex;
    /* padding: 5px; */
    border-radius: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    /* margin: 5px; */
    border: none;
    cursor: pointer;
    /* background-color: #4CAF50; */
    font-size: 16px;
    /* padding: 7px; */
    border-width: 2px;
    border-color: #db8c03;
    border-style: solid;
}
.jam-tb-button {
    cursor: pointer;
    font-size: 16px;
    padding: 5px;
}
.jam-input-tb {
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s;
}

/* Стили таблицы (IKSWEB) */
table.iksweb{text-decoration: none;border-collapse:collapse;width:100%;text-align:left;}
table.iksweb th{font-weight:normal;font-size:14px; color:#ffffff;background-color:#354251;}
table.iksweb td{font-size:15px;color:#354251;}
table.iksweb td,table.iksweb th{white-space:pre-wrap;padding:17px 9px;line-height:15px;vertical-align: middle;border: 1px solid rgb(255, 255, 255); border-bottom-color: rgb(0, 0, 0);}	table.iksweb tr:hover{background-color:#f9fafb}
table.iksweb tr:hover td{color:#354251;cursor:pointer;}
.mobile-table{width: 100%; max-width: 100%; overflow-x: auto;padding: 0px 0px 130px 0px;}
.jam-td-mob-name {
    display: none;
}
.jam-header-control {
    display: none;
}
.jam-tb-select {
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s;
}
.jam-header {
    width: 100%;
    overflow:hidden;
    position: fixed;
    margin: 0;
    background: #DDD;
    z-index: 999;
    display: flex;
    justify-content: space-evenly;
}
.jam-main {
    padding-top: 59px;
}
.jam-ul-header {
    display: flex;
    align-content: center;
    flex-direction: row;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
}
.jam-li-header {
    padding-left: 10px;
    display: flex;
    align-items: center;
}
.jam-img-icon-header {
    padding-left: 5px;
    width: 25px;
    height: 25px;
}
.jam-bottom {
    width: 100%;
    overflow: hidden;
    position: fixed;
    margin: 0;
    background: #DDD;
    z-index: 999;
    display: flex;
    justify-content: space-evenly;
    bottom: 0px;
}