
  dialog {
    position: fixed;
    height: 50%;
    width: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: none;
    padding: 0;
    background-color: #FFFFFF;
    color: #000000;
    text-align: center;
  }
  
  .openDialogBtn {
    position: fixed;
    bottom: 50px;
    right: 50px;
    min-width: 210px;
    border: 2px solid transparent;
    border-radius: 6px;
    font-size: 18px;
    font-weight: 300;
    font-family: inherit;
    transition: background-color 0.2s linear;
  }
  
  .closeDialogBtn {
    margin: 15% auto 0;
    border: 2px solid transparent;
    min-width: 210px;
    border-radius: 6px;
    padding: 9px 15px;
    color: #000000;
    font-size: 18px;
    font-weight: 300;
    font-family: inherit;
    transition: background-color 0.2s linear;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  }
  
  .dialog__wrapper {
    padding: 1em;
  }
  
  dialog::backdrop {
    background-color: rgb(0 0 0 / 0.8);
  }
  
  .scroll-lock {
    overflow: hidden;
  }

  .comments {
    padding: 5px;
  }

  .jam-td-mob-main {
    display: none;
    margin: 0px 0px 10px 0px;
  }
  .jam-filtr-main{
    display: flex;
    padding: 10px 0px 10px 0px;
  }
  .jam-filtr{
    display: flex;
    padding: 0px 0px 0px 10px;
  }
  .btn-add-status {
    margin: 0px 0px 20px 0px;
  }
  .add-status {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  @media (max-width: 1200px) { 
    .jam-td-mob-main {
      display: flex;
      margin: 0px 0px 10px 0px;
    }
    .jam-filtr-main {
      display: flex;
      /* padding: 10px 0px 10px 0px; */
      flex-direction: column;
    }
    .jam-filtr {
      display: flex;
      /* padding: 10px 0px 10px 0px; */
      flex-direction: column;
    }
  }