
@media (max-width: 1200px) {

    table.iksweb{text-decoration: none;border-collapse:collapse;width:100%;text-align:left;}
    table.iksweb th{font-weight:normal;font-size:14px; color:#ffffff;background-color:#354251; display: none;}
    table.iksweb td{font-size:15px;color:#354251;}
    table.iksweb td,table.iksweb th{white-space:pre-wrap;padding:17px 9px;line-height:15px;vertical-align: middle;border: 1px solid rgb(255, 255, 255); border-bottom-color: rgb(255, 255, 255);}	table.iksweb tr:hover{background-color:#f9fafb}
    table.iksweb tr {color:#354251;cursor:pointer;display: flex;flex-direction: column;border-bottom: 1px solid rgb(0, 0, 0);}
    table.iksweb tr:hover td{color:#354251;cursor:pointer;border: 2px solid rgb(0, 0, 0);}
    .mobile-table{width: 100%; max-width: 100%; overflow-x: auto;}
    .jam-td-mob-name {
        display: flex;
        margin: 0px 0px 10px 0px;
    }
    .jam-td-mob-main {
        display: flex;
        margin: 0px 0px 10px 0px;
      }
    .jam-header-control {
        display: flex;
        justify-content: flex-end;
    }
    .jam-header-control-op {
        margin: 10px;
    }
    .jam-button-header {
        /* padding: 5px; */
        border-radius: 10px;
        width: 100%;
        /* margin: 5px; */
        border: none;
        cursor: pointer;
        /* background-color: #4CAF50; */
        width: 100%;
        font-size: 16px;
        /* padding: 7px; */
        /* margin: 10px; */
        border-width: 2px;
        border-color: #354251;
        border-style: solid;
        padding: 0px;
        margin: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .jam-button-header:hover {
        /* padding: 5px; */
        border-radius: 10px;
        width: 100%;
        /* margin: 5px; */
        border: none;
        cursor: pointer;
        /* background-color: #4CAF50; */
        width: 100%;
        font-size: 16px;
        /* padding: 7px; */
        /* margin: 10px; */
        border-width: 2px;
        border-color: #354251;
        border-style: solid;
        padding: 0px;
        margin: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .jam-header {
        position: absolute;
        width: 50%;
        height: 100%;
        overflow: hidden;
        position: fixed;
        margin: 0;
        background: #DDD;
        z-index: 9999;
        display: none;
        justify-content: space-evenly;
        flex-direction: column;
        align-items: center;
    }
    .jam-ul-header {
        display: flex;
        align-content: center;
        align-items: stretch;
        margin-top: 5px;
        margin-bottom: 5px;
        flex-direction: column;
        padding: 5px;
    }
    .jam-div-button {
        justify-content: center;
    }
}


@media (max-width: 1024px) {
    
    .jam-button {
        /* padding: 5px; */
        border-radius: 10px;
        /* margin-top: 5px;
        margin-bottom: 5px; */
        width: 100%;
        /* margin: 5px; */
        border: none;
        cursor: pointer;
        /* background-color: #4CAF50; */
        width: 100%;
        font-size: 16px;
        /* padding: 7px; */
        /* margin: 10px; */
        border-width: 2px;
        border-color: #354251;
        border-style: solid;
    }
    .jam-button:hover {
        /* padding: 5px; */
        border-radius: 10px;
        /* margin-top: 5px;
        margin-bottom: 5px; */
        width: 100%;
        /* margin: 5px; */
        border: none;
        cursor: pointer;
        /* background-color: #4CAF50; */
        width: 100%;
        font-size: 16px;
        /* padding: 7px; */
        /* margin: 10px; */
        border-width: 2px;
        border-color: #db8c03;
        border-style: solid;
    }
    .jam-span-button {
        padding: 0px;
    }
    .jam-form-control-button {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin: 0px;
        padding: 0px;
    }    
    .jam-form-button {
        display: flex
;
        /* padding: 0px 55px 0px 55px; */
        flex-direction: column;
        align-content: space-around;
        /* width: 100%; */
        align-items: center;
        padding: 20px;

    }
    .jam-form-control {
        width: 90%;
    }
}

